<template>
  <div class="content cash-flow-item-list">
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <CommonSelect
            :isBu="true"
            :list="$store.state.settlementBuList"
            :placeholder="$t('结算组织')"
            :code.sync="formData.businessUnitCode"
            @change="searchHandle(1)"
          />
        </a-col>
        <a-col :span="6">
          <CommonQuerySelect
            :placeholder="$t('现金流量表')"
            :init="true"
            api="getCashFlowTabletist"
            :name.sync="formData.cashFlowTableCodeName"
            :code.sync="formData.cashFlowTableCode"
            @change="searchHandle(1)"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <div class="flex">
      <div class="left-content" :style="{ height: scrollHeight + 'px' }">
        <a-tree
          v-if="showTree"
          :tree-data="treeList"
          :selectedKeys.sync="selectedKeys"
          :defaultExpandedKeys="['all']"
          @select="onSelect"
        />
      </div>
      <div class="right-content">
        <CommonTable
          ref="CommonTableRef"
          :columns.sync="columns"
          :loading="loading"
          :dataSource="tableData"
          :total="total"
          rowKey="id"
          :scroll="{
            y: scrollHeight - 110,
          }"
          :showSetting="false"
          @search="getList"
        >
          <template v-slot:code="{ text, record }">
            <a @click="viewDetail(record)">{{ text }}</a>
          </template>
        </CommonTable>
      </div>
    </div>

    <a-modal
      width="800px"
      :title="$t('现金流量项目')"
      :visible="visible"
      :footer="null"
      @cancel="visible = false"
    >
      <a-radio-group v-model="modalPageType">
        <a-radio-button value="base">{{ $t('基本信息') }}</a-radio-button>
        <a-radio-button value="dimension">{{ $t('核算维度') }}</a-radio-button>
        <a-radio-button value="account">{{ $t('科目列表') }}</a-radio-button>
      </a-radio-group>

      <div class="mt20">
        <a-row :gutter="16" v-show="modalPageType !== 'base'">
          <a-col :span="12">
            <a-form-model-item :label="$t('编码')">
              <a-input v-model="modalData.code" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item :label="$t('名称')">
              <a-input v-model="modalData.name" disabled />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-show="modalPageType === 'base'">
          <a-form-model>
            <a-col :span="12">
              <a-form-model-item :label="$t('编码')">
                <a-input v-model="modalData.code" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('名称')">
                <a-input v-model="modalData.name" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('项目类别')">
                <a-input :value="$store.getters.cashFlowTypeMapping[modalData.type]" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('现金流向')">
                <a-input
                  :value="$store.getters.fundingDirectionMapping[modalData.fundingDirection]"
                  disabled
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('经营活动')">
                <a-switch
                  size="small"
                  :checked="Boolean(+modalData.isOperatingActivities)"
                  disabled
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('汇率变动')">
                <a-switch
                  size="small"
                  :checked="Boolean(+modalData.isExchangeRateFluctuations)"
                  disabled
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('净利润')">
                <a-switch size="small" :checked="Boolean(+modalData.isNetProfit)" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('现金流量通知单')">
                <a-switch size="small" :checked="Boolean(+modalData.isNotify)" disabled />
              </a-form-model-item>
            </a-col>
          </a-form-model>
        </a-row>
        <CommonTable
          v-show="modalPageType === 'dimension'"
          :columns.sync="dimensionColumns"
          :dataSource="modalData.dimensionList"
          :showSetting="false"
          :showPagination="false"
        ></CommonTable>
        <CommonTable
          v-show="modalPageType === 'account'"
          :columns.sync="accountColumns"
          :dataSource="accountList"
          :showSetting="false"
          :showPagination="false"
        ></CommonTable>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import { convertKeysToCamelCase, buildTree2 } from '@/common'
export default {
  name: 'cashFlowItemList',
  data() {
    return {
      docType: 'cashFlowItemList',
      formData: {
        parentCode: undefined,
      },
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      total: 0,
      loading: false,

      columns: [],
      dimensionColumns: [],
      accountColumns: [],
      selectedKeys: [],
      treeList: [],
      showTree: false,
      treeMap: {},

      visible: false,

      modalPageType: 'base',
      modalData: {},
      accountList: [],
    }
  },
  mounted() {
    this.setColumns()
    this.getTreeList()
  },
  activated() {
    this.searchHandle()
  },
  computed: {
    scrollHeight() {
      return window.innerHeight - 220
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.setColumns()
      this.searchHandle(1)
    },
    setColumns() {
      this.columns = [
        {
          title: this.$t('编码'),
          dataIndex: 'code',
          width: 120,
          scopedSlots: { customRender: 'code' },
        },
        {
          title: this.$t('名称'),
          dataIndex: 'name',
        },
        {
          title: this.$t('核算维度'),
          dataIndex: 'dimensionList',
          customRender: (text) => {
            return text?.map((item) => item.accountingDimensionName).join('/')
          },
        },
        {
          title: this.$t('项目类别'),
          dataIndex: 'type',
          customRender: (text) => this.$store.getters.cashFlowTypeMapping[text],
        },
        {
          title: this.$t('现金流向'),
          dataIndex: 'fundingDirection',
          customRender: (text) => this.$store.getters.fundingDirectionMapping[text],
        },
        {
          title: this.$t('经营活动'),
          dataIndex: 'isOperatingActivities',
          align: 'center',
          customRender: (text) => (text ? <i class="iconfont icon-check"></i> : ''),
        },
        {
          title: this.$t('汇率变动'),
          dataIndex: 'isExchangeRateFluctuations',
          align: 'center',
          customRender: (text) => (text ? <i class="iconfont icon-check"></i> : ''),
        },
        {
          title: this.$t('净利润'),
          dataIndex: 'isNetProfit',
          align: 'center',
          customRender: (text) => (text ? <i class="iconfont icon-check"></i> : ''),
        },
        {
          title: this.$t('现金流量通知单'),
          dataIndex: 'isNotify',
          align: 'center',
          customRender: (text) => (text ? <i class="iconfont icon-check"></i> : ''),
        },
        // {
        //   title: this.$t('创建组织'),
        //   dataIndex: 'bussinessUnitName',
        // },
        {
          title: this.$t('使用状态'),
          dataIndex: 'status',
          customRender: (text) => (+text ? this.$t('可用') : this.$t('停用')),
        },
        {
          title: this.$t('系统预置'),
          dataIndex: 'isPreset',
          align: 'center',
          customRender: (text) => (text ? <i class="iconfont icon-check"></i> : ''),
        },
      ]

      this.dimensionColumns = [
        {
          title: this.$t('编码'),
          dataIndex: 'accountingDimensionCode',
        },
        {
          title: this.$t('核算维度'),
          dataIndex: 'accountingDimensionName',
        },
      ]

      this.accountColumns = [
        {
          title: this.$t('科目编码'),
          dataIndex: 'account_code',
        },
        {
          title: this.$t('科目名称'),
          dataIndex: 'account_name',
        },
        {
          title: this.$t('余额方向'),
          dataIndex: 'direction',
          customRender: (text) => this.$store.getters.balanceDirectionMapping[text],
        },
      ]
    },
    searchHandle(pageNo) {
      if (!this.formData.cashFlowTableCode) return
      if (!this.formData.businessUnitCode) return
      this.selectedRows = []
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    async getTreeList() {
      let res = await http({
        url: api.getCashFlowItemList,
        type: 'post',
        data: {},
      })
      const { tree, treeMap } = buildTree2(res.result.list)
      this.treeList = [
        {
          title: this.$t('全部'),
          key: 'all',
          children: tree,
        },
      ]
      this.treeMap = treeMap
      this.showTree = true
    },
    onSelect(selectedKeys) {
      const code = selectedKeys[0]
      this.formData.nameOrCode = undefined
      this.formData.parentCode = undefined
      if (selectedKeys.length) {
        if (code === 'all') {
          this.formData.parentCode = undefined
        } else if (this.treeMap[code].children.length > 0) {
          this.formData.parentCode = code
        } else {
          this.formData.nameOrCode = code
        }
      }
      this.searchHandle(1)
    },
    async getList(params) {
      this.loading = true
      await http({
        url: api.getCashFlowItemList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    viewDetail(record) {
      this.visible = true
      this.modalData = record
      this.getCashFlowItemAccountMappingList()
    },
    getCashFlowItemAccountMappingList() {
      http({
        url: api.getCashFlowItemAccountMappingList,
        data: {
          cashFlowTableCode: this.modalData.cashFlowTableCode,
          nameOrCode: this.modalData.code,
        },
        success: (res) => {
          this.accountList = res.result.list
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.left-content {
  width: 300px;
  overflow: auto;
}
.right-content {
  flex: 1;
}
.icon-check {
  color: green;
  font-size: 20px;
  font-weight: bold;
}
</style>
